/**
 * @generated SignedSource<<38f6759ff6abf64975aad84ebdcc7d28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type userMFAQuery$variables = {
  login: string;
  password: string;
  rememberToken?: string | null;
};
export type userMFAQuery$data = {
  readonly mfaStrategy: {
    readonly lockedOut: boolean;
    readonly remembered: boolean | null;
    readonly required: boolean;
    readonly strategy: string;
    readonly validCredentials: boolean;
  } | null;
};
export type userMFAQuery = {
  response: userMFAQuery$data;
  variables: userMFAQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "login"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rememberToken"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "login",
        "variableName": "login"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "rememberToken",
        "variableName": "rememberToken"
      }
    ],
    "concreteType": "MfaStrategy",
    "kind": "LinkedField",
    "name": "mfaStrategy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "required",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "strategy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remembered",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lockedOut",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validCredentials",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userMFAQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userMFAQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "60e7beba951936a1463fbba32a3ffe77",
    "id": null,
    "metadata": {},
    "name": "userMFAQuery",
    "operationKind": "query",
    "text": "query userMFAQuery(\n  $login: String!\n  $password: String!\n  $rememberToken: String\n) {\n  mfaStrategy(login: $login, password: $password, rememberToken: $rememberToken) {\n    required\n    strategy\n    remembered\n    lockedOut\n    validCredentials\n  }\n}\n"
  }
};
})();

(node as any).hash = "74e28c42eeeda300f8b664a365fd9a1b";

export default node;
