import type { User } from 'shared/utils/logging/types/User'

/**
 * @see https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
 */

const identify = (user: User) => {
  if (window?.FS) {
    const { id, company, full_name } = user
    window.FS.identify(id?.toString(), {
      companyName: company?.name,
      companyType: company?.type,
      displayName: full_name,
      userId: id?.toString(),
    })
  }
}

export default {
  identify,
}
