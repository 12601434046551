/**
 * Router context.
 */

class Context {
  router5 = null

  CONTEXT_CHANGED = 'context_changed'

  setRouter5 = (router5) => {
    this.router5 = router5
  }

  swoopContext = () => this.router5?.getState()?.path || ''

  navigate = (routeName, params = null) => {
    // remove leading # and /
    const cleanedRouteName = routeName.replace(/^[#/]+/, '')
    this.router5?.navigate(cleanedRouteName, params)
  }

  /**
   * Remove param from URL without refresh.
   */

  deleteParam = (param) => {
    const route = this.router5?.getState()
    delete route.params[param]
    this.router5?.replaceHistoryState(route.name, route.params)
  }

  /**
   * Replace param (URL state) without refresh.
   */

  replaceParam = (oldParam, newParam, value = null) => {
    const route = this.router5?.getState()
    delete route.params[oldParam]
    route.params[newParam] = value
    this.router5?.replaceHistoryState(route.name, route.params)
  }
}

const context = new Context()

export default context
