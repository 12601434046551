import Consts from 'consts'
import Dispatcher from 'shared/utils/dispatcher'
import NotificationStore from 'stores/notificationStore'

import { localize } from 'shared/utils/languageUtils'
import {
  sendNotification as newSendNotification,
  removeNotification as newRemoveNotification,
  type Type,
} from '../design-system/components/Notifications/notificationUtils'

const removeNotification = (template: string) => {
  // localize or interpolate the message
  const localizedMessage = localize(template)
  NotificationStore.removeNotification(localizedMessage)
  newRemoveNotification(localizedMessage)
}

type Trigger =
  | typeof Consts.NOTIFICATION_TRIGGERS.CHANGE
  | typeof Consts.NOTIFICATION_TRIGGERS.NEW_NOTIFICATION
  | typeof Consts.NOTIFICATION_TRIGGERS.CLOSE_NOTIFICATION

const sendNotification = (
  template: string,
  type: Type = Consts.NOTIFICATION_TYPES.INFO,
  opts: Record<string, any> | undefined = undefined,
  dispatcherEvent: Trigger = Consts.NOTIFICATION_TRIGGERS.NEW_NOTIFICATION
) => {
  // localize or interpolate the message
  const localizedMessage = localize(template)

  // send notification to appear in the UI
  if (Dispatcher && Dispatcher.send) {
    setTimeout(() => {
      Dispatcher.send(dispatcherEvent, {
        dismissable: true,
        message: localizedMessage,
        timeout: 2000,
        type,
        ...opts,
      })
    }, 0)
  }

  newSendNotification(localizedMessage, type, opts)
}

export { removeNotification, sendNotification }
