import LegacyStorage from 'web-client/utils/storage'
import createUUID from 'web-client/utils/createUUID'
import Consts from 'consts'

const BEARER = 'bearer'
const REFRESH = 'refresh'
const EXPIRES_AT = 'expires_at'
const FALLBACK = 'fallback'
const FLOW_ID = 'flowId'
const MFA_REMEMBER_TOKEN = 'mfa_remember_token'

// Convert timeout in X minutes, converted to milliseconds. X min * 60 seconds * 100
const SESSION_REFRESH_TIMEOUT_MS = Consts.SESSION_REFRESH_TIMEOUT_MINUTES * 60 * 1000

type ImplentationMethod = 'setBearerToken' | 'setExpiresAt' | 'logout'

const sessionService = {
  isLoggingOut: false,

  implementation: {
    setBearerToken(_token?: string | null) {
      throw new Error('Not implemented')
    },

    setExpiresAt(_expiresAt?: number | null) {
      throw new Error('Not implemented')
    },

    logout() {
      throw new Error('Not implemented')
    },
  },

  inject(methodName: ImplentationMethod, method: () => never) {
    sessionService.implementation[methodName] = method
  },

  remove(key: string) {
    LegacyStorage.remove(key)
  },

  get(key: string, { skipCache }: { skipCache?: boolean } = {}) {
    return LegacyStorage.get(key, { skipCache })
  },

  set(key: string, val: string) {
    LegacyStorage.set(key, val)
  },

  hasBearerToken() {
    return !!sessionService.getBearerToken()
  },

  getBearerToken() {
    return sessionService.get(BEARER)
  },

  setBearerToken(token: string) {
    if (sessionService.isLoggingOut) {
      return
    }

    sessionService.set(BEARER, token)
    sessionService.implementation.setBearerToken(token)
  },

  removeBearerToken() {
    sessionService.remove(BEARER)
    sessionService.implementation.setBearerToken(null)
  },

  hasRefreshToken() {
    return !!sessionService.getRefreshToken()
  },

  getRefreshToken() {
    return sessionService.get(REFRESH)
  },

  setRefreshToken(token: string) {
    if (sessionService.isLoggingOut) {
      return
    }

    sessionService.set(REFRESH, token)
  },

  removeRefreshToken() {
    sessionService.remove(REFRESH)
  },

  hasExpiresAt() {
    return !!sessionService.getBearerToken()
  },

  shouldRefresh() {
    if (!this.hasExpiresAt()) {
      return true
    }

    const currentTime = new Date().getTime()
    return currentTime > this.getExpiresAt() - SESSION_REFRESH_TIMEOUT_MS
  },

  setExpiresAt(expiresAt: number) {
    sessionService.set(EXPIRES_AT, expiresAt.toString())
    sessionService.implementation.setExpiresAt(expiresAt)
  },

  setExpiresAtFromExpiresIn(expiresInSeconds: string) {
    const currentTimestamp = new Date().getTime()
    const expiresAt = currentTimestamp + parseInt(expiresInSeconds, 10) * 1000
    sessionService.setExpiresAt(expiresAt)
  },

  getExpiresAt() {
    return parseInt(sessionService.get(EXPIRES_AT) ?? '', 10)
  },

  removeExpiresAt() {
    sessionService.remove(EXPIRES_AT)
    sessionService.implementation.setExpiresAt(null)
  },

  getFallbackToken() {
    return sessionService.get(FALLBACK)
  },

  hasFallbackToken() {
    return !!sessionService.getFallbackToken()
  },

  setFallbackToken(token: string) {
    if (sessionService.isLoggingOut) {
      return
    }

    sessionService.set(FALLBACK, token)
  },

  removeFallbackToken() {
    sessionService.remove(FALLBACK)
  },

  createId() {
    const uuid = createUUID()
    sessionService.set(FLOW_ID, uuid)
    return uuid
  },

  getId() {
    return sessionService.get(FLOW_ID)
  },

  removeId() {
    sessionService.remove(FLOW_ID)
  },

  clear() {
    sessionService.removeBearerToken()
    sessionService.removeRefreshToken()
    sessionService.removeExpiresAt()
    sessionService.removeFallbackToken()
  },

  setLoginFlashMessage(message: string) {
    sessionService.set(Consts.LOGIN_FLASH_ERROR_MESSAGE_KEY, message)
  },

  setMfaRememberToken(token: string) {
    sessionService.set(MFA_REMEMBER_TOKEN, token)
  },

  getMfaRememberToken() {
    return sessionService.get(MFA_REMEMBER_TOKEN)
  },

  removeMfaRememberToken() {
    return sessionService.remove(MFA_REMEMBER_TOKEN)
  },
}

export default sessionService
