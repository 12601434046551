import type { ComponentType } from 'react'
// eslint-disable-next-line no-restricted-imports -- We allow for this file, as this is what it should be replaced by
import { lazy } from 'react'
import retry from './retry'

function lazyRetry<T extends ComponentType<any>>(importCallback: () => Promise<{ default: T }>) {
  return lazy(() => retry(importCallback))
}

export default lazyRetry
