import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { darken } from 'polished'

import colors from 'design-system/tokens/colors'
import fontSizes from 'design-system/tokens/fontSizes'
import fontWeights from 'design-system/tokens/fontWeights'
import spacing from 'design-system/tokens/spacing'

import type { SwoopColor } from 'design-system/tokens/colors'
import lineHeights from 'design-system/tokens/lineHeights'

export type Props = {
  secondary?: boolean
  tertiary?: boolean
  customColor?: SwoopColor
  size?: 's' | 'm' | 'l' | 'c'
}

const Button = styled.button`
  padding: 0 ${spacing.m1};
  font-weight: ${fontWeights.semibold};

  ${({ size }: Props) => {
    if (size === 's') {
      return css`
        font-size: ${fontSizes.bodySmall};
        line-height: 36px;
      `
    } else if (size === 'm') {
      return css`
        font-size: ${fontSizes.bodySmall};
        line-height: 40px;
      `
    } else if (size === 'l') {
      return css`
        font-size: ${fontSizes.body};
        line-height: 48px;
      `
    } else if (size === 'c') {
      return css`
        font-size: ${fontSizes.bodySmall};
        line-height: 26px;
        padding-left: ${spacing.xxs};
        padding-right: ${spacing.xxs};
      `
    } else {
      // when no size is declared, use `m` as default; intentionally WET
      return css`
        font-size: ${fontSizes.bodySmall};
        line-height: 40px;
      `
    }
  }}

  ${({ customColor, secondary, tertiary }: Props) => {
    if (customColor) {
      return css`
        background: ${customColor};

        :hover {
          background: ${darken(0.1, customColor)};
        }

        :active {
          background: ${darken(0.2, customColor)};
        }
      `
    } else if (tertiary) {
      return css`
        background: ${colors.alerts.green};

        :hover {
          background: ${darken(0.1, colors.alerts.green)};
        }
        :active {
          background: ${darken(0.2, colors.alerts.green)};
        }
      `
    } else if (secondary) {
      return css`
        background: ${colors.grays.l2};

        :hover {
          background: ${darken(0.1, colors.grays.l2)};
        }
        :active {
          background: ${colors.grays.l1};
        }
      `
    } else {
      return css`
        background: ${colors.primary};

        :hover {
          background: ${colors.blues.midrange};
        }
        :active {
          background: ${colors.blues.dark};
        }
      `
    }
  }}

  border: 0;
  border-radius: 4px;
  color: ${colors.white};
  cursor: pointer;
  font-family: inherit;
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  :disabled {
    background: ${colors.grays.l6};
    color: ${colors.grays.l4};
  }
`

export const GhostButton = styled(Button)`
  background: none;
  border: 1px solid ${colors.primary};
  color: ${colors.primary};

  :hover {
    background: ${colors.blues.midrange}20;
  }

  :active {
    background: ${colors.blues.dark}20;
  }

  :disabled {
    background: none;
    border-color: ${colors.grays.l3};
    color: ${colors.grays.l3};

    :hover {
      background: ${colors.grays.l3}20;
    }
  }
`

export const IconButton = styled(Button)`
  padding: 0 14px;
`

export const ButtonTextLink = styled(GhostButton)`
  background: none;
  border: 0;
  color: ${colors.primary};
  font-weight: normal;
  text-align: center;
  text-decoration: none;

  :active,
  :hover {
    background: none;
  }

  :disabled {
    color: ${colors.grays.l3};
  }
`
export const MenuButton = styled.button`
  color: ${colors.grays.l1};
  cursor: pointer;
  font-size: ${fontSizes.body};
  font-weight: ${fontWeights.semibold};
  line-height: ${lineHeights.option};
  border: 0;
  background: none;
  padding-left: 0;
  width: 100%;
  text-align: left;
  :hover {
    color: ${colors.primary};
  }
`
export default Button
