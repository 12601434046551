import type { ReactNode, PropsWithChildren } from 'react'
import React, { useContext, createContext, Component } from 'react'
import logging from 'shared/utils/logging'

type Props = PropsWithChildren<{
  errorRender?: () => ReactNode
  errorPrefix?: string
}>

type ErrorBoundaryContextProviderProps = (error: Error | string) => void

const ErrorBoundaryContext = createContext<ErrorBoundaryContextProviderProps>(() => null)

export const useTriggerError = () => {
  return useContext(ErrorBoundaryContext)
}
class ErrorBoundary extends Component<Props> {
  state = { hasError: false }

  constructor(props: Props) {
    super(props)
    this.triggerErrorBoundary = this.triggerErrorBoundary.bind(this)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error | string, errorInfo: React.ErrorInfo) {
    const errorObj = error instanceof Error ? error : new Error(error)
    if (window.Rollbar) {
      const { errorPrefix } = this.props
      // add error message prefix to show more indication in rollbar
      const message = `!!Render Issue!! ${errorPrefix ? `${errorPrefix} ` : ''}${errorObj.message}`
      logging.logError(message, error, {
        ...errorInfo,
        ...(errorObj.cause ? { cause: errorObj.cause } : {}),
      })
    }
  }

  triggerErrorBoundary(error: Error | string = new Error('Error boundary triggered')) {
    this.componentDidCatch(error, {})
    setTimeout(() => {
      this.setState({
        hasError: true,
      })
    }, 0)
  }

  render() {
    const { children, errorRender } = this.props

    if (this.state.hasError) {
      return errorRender ? (
        errorRender()
      ) : (
        <p>Sorry, there was an error and we have been notified.</p>
      )
    }

    return (
      // It is a bound method
      // eslint-disable-next-line @typescript-eslint/unbound-method
      <ErrorBoundaryContext.Provider value={this.triggerErrorBoundary}>
        {children}
      </ErrorBoundaryContext.Provider>
    )
  }
}

export default ErrorBoundary
