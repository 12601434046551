const clearUrlHash = () => {
  window.history.replaceState(null, '', ' ')
}

const clearQuery = () => {
  window.history.replaceState(null, '', window.location.pathname)
}

const getUrlHash = () => {
  return window.location.hash
}

const hasUrlHash = () => {
  return !!getUrlHash()
}

export default {
  clearUrlHash,
  clearQuery,
  getUrlHash,
  hasUrlHash,
}
