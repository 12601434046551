import type { Context } from '@datadog/browser-core'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { merge, cloneDeep } from 'lodash'
import Consts from 'consts'
import throttle from '../../throttle'

let TAP = window.ENV?.FE_DATADOG_TO_CONSOLE ?? false

const initialize = () => {
  /**
   * Initialize datadog.
   *
   * @see https://github.com/DataDog/browser-sdk
   */

  if (Consts.DATADOG_RUM_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: Consts.DATADOG_RUM_APPLICATION_ID,
      clientToken: Consts.DATADOG_RUM_CLIENT_TOKEN,
      sessionSampleRate: Consts.DATADOG_RUM_SAMPLE_RATE,
      trackUserInteractions: true,
      env: Consts.DATADOG_RUM_ENV,
      version: window.VERSION,
    })
  }

  if (Consts.DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: Consts.DATADOG_CLIENT_TOKEN,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      version: window.VERSION,
    })

    datadogLogs.setGlobalContextProperty(
      'env',
      Consts.DATADOG_ENV === 'review' ? window.location.host.split('.')[0] : Consts.DATADOG_ENV
    )

    datadogLogs.setGlobalContextProperty('host', window.location.host)
  }
}

/**
 * Log info.
 */

type Queue = Parameters<typeof throttle>['0']

const queue = {
  error: {},
  warn: {},
  debug: {},
  info: {},
}

const log = throttle(queue as Queue, datadogLogs.logger as Parameters<typeof throttle>['1'], 100)

/**
 * This is so you can set data on every log call.
 *
 * This method is necessary to avoid the following dependency cycle:
 * The relay environment,
 * which is required to fetch session context for the logging package,
 * requires the logging package to log fetching issues.
 */

const props: Context = {}

/**
 * This is so we can "reach back" and get **dynamic props**,
 * such as window.FS.getCurrentSessionURL(true), which is based on the
 * current *time* the user is visiting the page, so the log needs to
 * get the latest value.
 */

const callbacks: { [key: string]: () => unknown } = {}

const clearCallback = (name: string) => {
  delete callbacks[name]
}

const setCallback = (name: string, callback: () => Context) => {
  callbacks[name] = callback
}

const mergeProps = (data: Context) => {
  merge(props, data)
}

/**
 * Build properties from props, callbacks, and passed in data.
 */

const buildProps = (data: Context) => {
  const mergedProps = merge({}, cloneDeep(props))
  merge(mergedProps, data)

  Object.keys(callbacks).forEach((key) => {
    const callbackProps = callbacks[key]()
    merge(mergedProps, callbackProps)
  })

  return mergedProps
}

const tap = (value = true) => {
  TAP = value
}

const logDebug = (name: string, data: Context) => {
  if (!Consts?.DATADOG_CLIENT_TOKEN) {
    return
  }

  const input = buildProps(data)
  if (TAP) {
    console.info('%c datadog.debug', 'color:green;', name, input)
  }
  log(name, input, 'debug')
}

const logInfo = (name: string, data: Context) => {
  if (!Consts?.DATADOG_CLIENT_TOKEN) {
    return
  }

  const input = buildProps(data)
  if (TAP) {
    console.info('%c datadog.info', 'color:green;', name, input)
  }
  log(name, input, 'info')
}

const logWarning = (name: string, data: Context) => {
  if (!Consts?.DATADOG_CLIENT_TOKEN) {
    return
  }

  const input = buildProps(data)
  if (TAP) {
    console.info('%c datadog.warn', 'color:yellow;', name, input)
  }
  log(name, input, 'warn')
}

const logError = (name: string, data: object = {}) => {
  if (!Consts?.DATADOG_CLIENT_TOKEN) {
    return
  }

  let stackTrace = new Error().stack // Firefox does not support Error.captureStackTrace
  if (Error.captureStackTrace) {
    // preferrable as it is supported in Chrome and can eliminate un-necessary frames
    const obj = { stack: '' }
    Error.captureStackTrace(obj, logError)
    stackTrace = obj.stack
  }
  const input = buildProps({
    ...data,
    'error.stack': stackTrace,
  })
  if (TAP) {
    console.info('%c datadog.error', 'color:red;', name, input)
  }
  log(name, input, 'error')
}

initialize()

export default {
  initialize,
  logInfo,
  logWarning,
  logError,
  logDebug,
  mergeProps,
  setCallback,
  clearCallback,
  props,
  tap,
}
