import swoopFetch from 'web-client/utils/swoopFetch'
import logging from 'shared/utils/logging'
import Storage from 'web-client/utils/storage'
import type { OauthTokenResponse } from '.'

export const oauthRefreshRequest = async () => {
  const refreshToken = Storage.get('refresh') ?? ''
  const response = await swoopFetch('/oauth/token', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  })

  const {
    access_token: newAccessToken,
    error,
    error_description,
    refresh_token: newRefreshToken,
    expires_in: newExpiresIn,
  } = (await response.json()) as OauthTokenResponse

  if (!response.ok) {
    logging.logWarning(`Error refreshing token: ${error ? `${error}: ${error_description}` : ''}`)
  }

  if (!newAccessToken) {
    logging.logWarning('Error refreshing token: No access token in response.')
  }

  if (!newExpiresIn) {
    logging.logWarning('Error refreshing token: No expires_in in response.')
  }

  // This will cause a session logout and redirect to the login page.
  if (!response.ok || !newAccessToken || !newExpiresIn) {
    throw new Error('Error refreshing token')
  }

  return {
    bearer: newAccessToken,
    refresh: newRefreshToken,
    expires_in: newExpiresIn,
  }
}
