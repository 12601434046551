import React, { StrictMode } from 'react'
import rollbar from 'shared/utils/logging/integrations/rollbar'
import { createRoot } from 'react-dom/client'
import { getSplitFactory } from 'shared/components/split.io/utils'
import Consts from 'consts'
import type { JSX } from 'react/jsx-runtime'

const factory = getSplitFactory(undefined, [
  {
    type: 'byName',
    values: [Consts.SPLITS.ENABLE_REACT_STRICT_MODE],
  },
])
const client = factory.client()

const StrictModeWrapper = ({ children }: { children: JSX.Element }) => {
  if (client.getTreatment(Consts.SPLITS.ENABLE_REACT_STRICT_MODE) === 'on') {
    return <StrictMode>{children}</StrictMode>
  } else {
    return children
  }
}

const renderToDOM = (applicationComponent: JSX.Element) => {
  const container = document.getElementById('root')
  if (container) {
    let rendered = false
    client.on(client.Event.SDK_READY, () => {
      if (!rendered) {
        rendered = true
        const root = createRoot(container)
        root.render(<StrictModeWrapper>{applicationComponent}</StrictModeWrapper>)
      }
    })
    // Fallback in case split has a bug or is down
    setTimeout(() => {
      if (!rendered) {
        rendered = true
        const root = createRoot(container)
        root.render(applicationComponent)
      }
    }, 5000)
  } else {
    // eslint-disable-next-line no-alert -- we are outside of react so can't render a message to the user another way
    alert('No root container found')
    rollbar.logError('No root container found', { location: 'CMW' })
  }
}
export default renderToDOM
