import type {
  CacheConfig,
  GraphQLResponse,
  RequestParameters,
  Uploadable,
  Variables,
} from 'relay-runtime'
import { Observable } from 'relay-runtime'

import createActionCableHandler, { closeActionCable } from 'graphql/createActionCableHandler'
import type { Sink } from 'relay-runtime/lib/network/RelayObservable'

/*
  This module caches subscription requests until a handler is chosen,
  then routes everything to the chosen handler.
  Currently doesn't support switching handlers... but can be added if needed
*/

type CableHandler = (
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig | null,
  uploadables: Uploadable | null,
  sink: Sink<GraphQLResponse>,
  channelId: string
) => () => void

let cableHandler: CableHandler | null = null
// Holds subscription requests until a handler is chosen
export const activeSubscriptionMap: {
  [key: string]: {
    operation: RequestParameters
    variables: Variables
    sink: Sink<GraphQLResponse>
    channelId: string
  }
} = {}
let subscriptionCount = 0
type CreationOptions = { cable_type?: string }
let creationOptions: CreationOptions | null = null
export let actionCableProSelected: boolean | null = null

export const updateCableMethod = (actionCableProEnabled: boolean = false) => {
  if (
    (actionCableProEnabled && actionCableProSelected) ||
    (!actionCableProEnabled && actionCableProSelected === false)
  ) {
    return
  }

  closeActionCable()

  cableHandler = actionCableProEnabled
    ? createActionCableHandler({ cable_type: 'ActionCablePro' })
    : createActionCableHandler(creationOptions)

  actionCableProSelected = actionCableProEnabled

  // Handle previous subscriptions that need to be put on new cable
  for (const key in activeSubscriptionMap) {
    const { operation, variables, sink, channelId } = activeSubscriptionMap[key]
    cableHandler(operation, variables, null, null, sink, channelId)
  }
}

export default function createCableHandler(options: CreationOptions = {}) {
  if (creationOptions !== null) {
    console.error('creation of the cable handler should only happen once')
    return
  }
  creationOptions = options
  return (operation: RequestParameters, variables: Variables) => {
    const subscriptionId = (subscriptionCount += 1)
    return Observable.create<GraphQLResponse>((sink) => {
      // save subscriptions until we know where to route them to
      // unique-ish
      const channelId = Math.round(Date.now() + Math.random() * 100000).toString(16)

      activeSubscriptionMap[subscriptionId] = {
        operation,
        variables,
        sink,
        channelId,
      }

      // calls cable handler if it's been set already
      const unsubscribe = cableHandler?.(operation, variables, null, null, sink, channelId)

      // cleans up
      return () => {
        unsubscribe?.()
        delete activeSubscriptionMap[subscriptionId]
      }
    })
  }
}
