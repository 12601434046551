const isLatLngLiteral = (
  location:
    | {
        readonly lat: number | null | undefined
        readonly lng: number | null | undefined
      }
    | null
    | undefined
): location is google.maps.LatLngLiteral => {
  return typeof location?.lat === 'number' && typeof location?.lng === 'number'
}

export default isLatLngLiteral
