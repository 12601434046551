import logging from 'shared/utils/logging'

export function setUserMaskBearerToken(token: string) {
  try {
    sessionStorage.setItem('bearer', token)
  } catch (error) {
    logging.logWarning('Session Storage Write Fail', { error })
  }
}
export function getUserMaskBearerToken() {
  try {
    return sessionStorage.getItem('bearer')
  } catch (error) {
    logging.logWarning('Session Storage Get Fail', { error })
  }
}
export function removeUserMaskBearerToken() {
  try {
    sessionStorage.removeItem('bearer')
  } catch (error) {
    logging.logWarning('Session Storage Remove Fail', { error })
  }
}
