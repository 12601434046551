const swoopBlue = '#36bde8'

const colors = {
  swoopRed: '#ff7272',

  alerts: {
    green: '#3dcc79',
    red: '#ff695b',
    orange: '#ffa130',
    yellow: '#ffbe4f',
  },
  banner: {
    green: '#ECFAF2',
    red: '#FFF0EE',
    blue: '#EAF8FC',
    yellow: '#FFF8ED',
    gray: '#FAFAFA',
  },
  statuses: {
    yellow: '#e0b7fb',
    pink: '#e0b7fb',
    green: '#6beda5',
    purple: '#acb8fe',
    orange: '#fcc37f',
    red: '#ff7171',
  },

  blues: {
    highlight: '#F5FBFE',
    light: '#7fbce2',
    vibrant: '#59cbe8',
    midrange: '#2ca1d8',
    dark: '#0077c8',
  },
  grays: {
    l1: '#5a5c5f',
    l2: '#8d8d8d',
    l3: '#a9a9a9',
    l4: '#c5c5c5',
    l5: '#e1e1e1',
    l6: '#f2f2f2',
    l7: '#fafafa',
  },
  greens: {
    send: '#4bbf61',
    button: '#3ecc79',
  },

  black: '#272729',
  white: '#fff',
  primary: swoopBlue,
} as const

export type SwoopColor =
  | typeof colors.swoopRed
  | (typeof colors.alerts)[keyof typeof colors.alerts]
  | (typeof colors.statuses)[keyof typeof colors.statuses]
  | (typeof colors.blues)[keyof typeof colors.blues]
  | (typeof colors.grays)[keyof typeof colors.grays]
  | (typeof colors.greens)[keyof typeof colors.greens]
  | typeof colors.black
  | typeof colors.white
  | typeof colors.primary

export default colors
