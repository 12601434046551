import { omit } from 'lodash'

import Consts from 'consts'
import type { User } from 'shared/utils/logging/types/User'

import rollbar from '../rollbar'

/**
 * Declare globals.
 */

declare global {
  interface Window {
    gtag: (tag: string, name: string, data: unknown) => void
  }
}

/**
 * Define `TrackOptionsInterface`.
 */

export interface TrackOptionsInterface {
  [key: string]: string | number | undefined
  group?: string
  title?: string
  userId?: number
}

/**
 * @see https://support.google.com/analytics/answer/1033068#Anatomy
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets
 */

const track = (name: string, options: TrackOptionsInterface = {}) =>
  new Promise((resolve) => {
    const data = {
      event_category: options.group,
      event_label: options.title,
      event_callback: resolve,
      ...omit(options, ['group', 'title']),
    }

    try {
      window.gtag('event', name, data)
    } catch (e) {
      rollbar.logError(e as Rollbar.LogArgument)
    }
  })

/**
 * Identify a user.
 */

const identify = async (user: User) => {
  const { company, full_name } = user
  const companyName = company?.name
  const companyType = ''
  const userId = user.id
  const userFullName = full_name
  await track('identify', {
    group: 'Identities',
    title: userFullName,
    companyName,
    companyType,
    userId,
    userFullName,
  })
}

/**
 * Similar to segment's `analytics.page`.
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */

const visitPage = (path: string, title: string) => {
  try {
    window.gtag('config', Consts.GOOGLE_ANALYTICS_KEY, {
      page_title: title,
      page_path: path,
    })
  } catch (e) {
    rollbar.logError(e as Rollbar.LogArgument)
  }
}

/**
 * Expose module.
 */

export default {
  visitPage,
  identify,
  track,
}
