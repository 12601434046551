const headers = new Headers({
  'Content-Type': 'application/json',
  Accept: 'application/json; charset=utf-8',
})

function setBearerToken(token: string) {
  headers.set('Authorization', `Bearer ${token}`)
}

function clearBearerToken() {
  headers.delete('Authorization')
}

export default {
  setBearerToken,
  clearBearerToken,
  headers,
}
