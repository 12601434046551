/**
 * @generated SignedSource<<b771cb8b62c84d73b7ee0768f9f1c338>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecordLoginInput = {
  clientMutationId?: string | null;
};
export type auth0RecordLoginMutation$variables = {
  input: RecordLoginInput;
};
export type auth0RecordLoginMutation$data = {
  readonly recordLogin: {
    readonly user: {
      readonly id: string;
    } | null;
  } | null;
};
export type auth0RecordLoginMutation = {
  response: auth0RecordLoginMutation$data;
  variables: auth0RecordLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecordLoginPayload",
    "kind": "LinkedField",
    "name": "recordLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "auth0RecordLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "auth0RecordLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c2d12a430aff38e3be7d27e054a17cdf",
    "id": null,
    "metadata": {},
    "name": "auth0RecordLoginMutation",
    "operationKind": "mutation",
    "text": "mutation auth0RecordLoginMutation(\n  $input: RecordLoginInput!\n) {\n  recordLogin(input: $input) {\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03cfe5e13b28d491b1754ca2b5dcad3f";

export default node;
