import datadog from 'shared/utils/logging/integrations/datadog'
import startsWith from 'lodash/startsWith'

export const TYPE_ERROR = 'TypeError'
export const REFERENCE_ERROR = 'ReferenceError'

export function isBrowserExtension(trace: NonNullable<Rollbar.Payload['body']>['trace']) {
  return trace?.frames?.some(
    (frame) =>
      startsWith(frame?.filename, 'user-script:') ||
      startsWith(frame?.filename, 'webkit-masked-url:') ||
      startsWith(frame?.filename, 'chrome-extension:')
  )
}
const LOG_STATUS_CODES: Rollbar.StatusCodes = Object.freeze({
  '0': 1,
  '404': 1, // Not found
})

export const shouldSwallowError = (extra?: Rollbar.Extra) => {
  if (!extra) {
    return false
  }
  const { status, readyState } = extra
  if (readyState === 4 && !!status && !LOG_STATUS_CODES[status]) {
    return true
  }
  return false
}

export const logErrorToDatadog = (payload: Rollbar.Payload, isMobile = false) => {
  const message = payload?.body?.trace?.exception?.message || ''

  datadog.logError(`FE Error: ${message}`, {
    error: payload.body,
    message,
    isMobile,
  })
}
