import { JobStatus as JobStatusEnum } from 'graphql/enums'

export type JobStatus = keyof typeof JobStatusEnum

// Statuses that require location confirmation
export const CLSTATUS = new Array<JobStatus>(
  JobStatusEnum.AutoAssigning,
  JobStatusEnum.Created,
  JobStatusEnum.Pending,
  JobStatusEnum.Submitted,
  JobStatusEnum.Collecting,
  JobStatusEnum.Accepted,
  JobStatusEnum.Assigned,
  JobStatusEnum.Dispatched,
  JobStatusEnum.EnRoute,
  JobStatusEnum.Unpaid
)

// Statuses that show ETA
export const ETASTATUS = new Array<JobStatus>(
  JobStatusEnum.Accepted,
  JobStatusEnum.Dispatched,
  JobStatusEnum.Assigned,
  JobStatusEnum.EnRoute
)

// The order of status for CMW users
export const ORDERED_STATUSES = new Array<JobStatus>(
  JobStatusEnum.Created,
  JobStatusEnum.Pending,
  JobStatusEnum.Submitted,
  JobStatusEnum.Collecting,
  JobStatusEnum.AutoAssigning,
  JobStatusEnum.Accepted,
  JobStatusEnum.Assigned,
  JobStatusEnum.Dispatched,
  JobStatusEnum.ReassignRequested,
  JobStatusEnum.GOARequested,
  JobStatusEnum.UnsuccessfulRequested,
  JobStatusEnum.EnRoute,
  JobStatusEnum.OnSite,
  JobStatusEnum.Towing,
  JobStatusEnum.TowDestination,
  JobStatusEnum.Completed,
  JobStatusEnum.Canceled,
  JobStatusEnum.GOA,
  JobStatusEnum.Unsuccessful
)
