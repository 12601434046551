import type React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'design-system/tokens/colors'
import { px as iconPx } from 'design-system/tokens/iconSizes'
import type { IconSizes } from 'design-system/tokens/iconSizes'
import type { SwoopColor } from 'design-system/tokens/colors'

type IconProps = React.ComponentProps<typeof FontAwesomeIcon> & {
  iconSize?: IconSizes
  color?: SwoopColor
}

const Icon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'iconSize' && prop !== 'color',
})<IconProps>`
  && {
    color: ${({ color }) => color || colors.grays.l2};
    width: ${({ iconSize = 'large' }) => iconPx[iconSize]};
    height: ${({ iconSize = 'large' }) => iconPx[iconSize]};
    margin: 0;
  }
`

export default Icon
