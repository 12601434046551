import { getBaseUrl } from 'web-client/utils/baseUrl'
import worker from 'web-client/graphql/RequestWorker'

let currentRequestId = 0

type Resolutions = {
  [key: number]: (value: FetchResponse | PromiseLike<FetchResponse>) => void
}
const resolutions: Resolutions = {}

type Rejections = {
  [key: number]: (reason?: string) => void
}
const rejections: Rejections = {}

type AbortListeners = {
  [key: number]: {
    signal: { removeEventListener: (event: string, callback: () => void) => void }
    callback: () => void
  }
}
const abortListeners: AbortListeners = {}
const timeouts: Record<number, NodeJS.Timeout> = {}
if (!window.swoop) {
  window.swoop = {}
}
if (!window.swoop.pendingFetchCount) {
  window.swoop.pendingFetchCount = 0
}
export class FetchTimeoutError extends Error {
  readonly timeoutms: number

  constructor(message: string, timeoutms: number) {
    super(message)
    this.timeoutms = timeoutms
  }
}

const cleanupPromise = (requestId: number) => {
  if (abortListeners[requestId]) {
    const { signal, callback } = abortListeners[requestId]
    signal.removeEventListener('abort', callback)
    delete abortListeners[requestId]
  }
  delete resolutions[requestId]
  delete rejections[requestId]
  if (timeouts[requestId]) {
    clearTimeout(timeouts[requestId])
    delete timeouts[requestId]
    window.swoop.pendingFetchCount -= 1
  }
}

type MessagePayload = {
  ok: boolean
  requestId: number
  json?: unknown
  text?: string
  status?: number
}

type Message = {
  data: MessagePayload
}

const handleMessage = (msg: Message) => {
  const { json, text, status, requestId, ok } = msg.data
  const resolve = resolutions[requestId]
  cleanupPromise(requestId)
  if (resolve) {
    resolve({
      json: () => Promise.resolve(json),
      text: () => Promise.resolve(text || JSON.stringify(json)),
      status,
      ok,
    })
  }
}

export type FetchResponse = {
  ok: boolean
  json(): Promise<unknown> | undefined
  text(): Promise<string> | undefined
  status?: number
}

type ExtraProps = {
  timeoutms?: number
}

function ensureBaseUrl(request: string) {
  let url = request
  if (!request.includes('://')) {
    // TODO: if request is relative (is partial and doesn't start with an / we need to append the current full path.
    // however we don't want to support that in the future and i'm not familiar with any current instances so not including.
    url = new Request(`${getBaseUrl()}${request}`).url
  }
  return url
}

const swoopFetch = (
  request: RequestInfo,
  passedProps: RequestInit = {},
  extraProps: ExtraProps = {}
) => {
  window.swoop.pendingFetchCount += 1
  const url = typeof request === 'string' ? ensureBaseUrl(request) : request.url
  const { timeoutms = 30000 } = extraProps
  const promise: Promise<FetchResponse> = new Promise((resolve, reject) => {
    currentRequestId += 1
    const requestId = currentRequestId
    const { signal, headers, ...props } = passedProps
    if (signal) {
      const callback = () => {
        worker.postMessage({
          requestId,
          sender: 'Swoop',
          action: 'abort',
        })
      }
      signal.addEventListener('abort', callback)
      abortListeners[requestId] = {
        signal,
        callback,
      }
    }
    let requestHeaders: HeadersInit = {}
    if (headers) {
      if (headers instanceof Headers) {
        const headersObj: Record<string, string> = {}
        headers.forEach((value, key) => {
          headersObj[key] = value
        })
        requestHeaders = headersObj
      } else {
        requestHeaders = headers
      }
    }

    resolutions[requestId] = resolve
    rejections[requestId] = reject
    worker.postMessage({
      url,
      props: {
        ...props,
        headers: requestHeaders,
      },
      requestId,
      sender: 'Swoop',
      action: 'workerFetch',
    })

    timeouts[requestId] = setTimeout(() => {
      cleanupPromise(requestId)
      worker.postMessage({ requestId, sender: 'Swoop', action: 'abort' })
      reject(new FetchTimeoutError(`Fetch timeout error: ${url}`, timeoutms))
    }, timeoutms)
  })

  return promise
}

worker.addEventListener('message', handleMessage)

export default swoopFetch
