/* eslint-disable relay/unused-fields */

import { graphql, useQuery } from 'relay-hooks'
import { useMemo } from 'react'

import { getJobId, getUUID } from 'consumer-mobile-web/utils/jobutils'
import type { useCurrentJobQuery } from './__generated__/useCurrentJobQuery.graphql'

const useCurrentJob = () => {
  const uuid = getUUID()
  const jobId = getJobId()

  const { data, isLoading } = useQuery<useCurrentJobQuery>(
    graphql`
      query useCurrentJobQuery($id: ID!) {
        node(id: $id) {
          ... on Job {
            client {
              company {
                swcid
                name
                type
              }
            }
          }
        }
      }
    `,
    { id: String(jobId) },
    { skip: !uuid || !jobId }
  )

  const currentJob = useMemo(
    () => ({
      ...data?.node,
      company: data?.node?.client?.company,
    }),
    [data]
  )

  return {
    currentJob,
    isLoading,
  }
}

export default useCurrentJob
