import type { ComponentPropsWithoutRef } from 'react'
import React from 'react'

import Button from 'design-system/components/Button'
import EditFooter from './EditFooter'

type AddEditFooterProps = ComponentPropsWithoutRef<typeof EditFooter> & {
  isEditing: boolean
}

const AddEditFooter = ({ isSaveDisabled, ...props }: AddEditFooterProps) => {
  return (
    <EditFooter isSaveDisabled={isSaveDisabled} {...props}>
      <Button disabled={isSaveDisabled}>Add</Button>
    </EditFooter>
  )
}

export default AddEditFooter
