import { useRef } from 'react'

// Used to create an unchanging variable in your function with a callback (e.g. form creation)
// Param: create is a callback function meant to return the instance variable on first render
export function useInstance<T>(create: () => T) {
  const instance = useRef<T | null>(null)

  if (instance.current === null) {
    instance.current = create()
  }

  return instance.current
}
