import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

export default function contextWrapper(ContextConsumer) {
  // Needs to be a pure component because some of use cases expect Pure/Components
  return (Component) => {
    class ContextComponent extends React.PureComponent {
      render() {
        return (
          <ContextConsumer>
            {(contextProps) => <Component {...contextProps} {...this.props} />}
          </ContextConsumer>
        )
      }
    }
    return hoistNonReactStatics(ContextComponent, Component)
  }
}
