import React, { useContext, useEffect, useMemo, useState } from 'react'

import type { ComponentType } from 'react'

import useCurrentUser from 'web-client/hooks/useCurrentUser'

import { initSplit } from './utils'

type WithSplitIOContext = {
  client: SplitIO.IClient | null
  treatments: SplitIO.Treatments | null
}

const SplitIOContext = React.createContext<WithSplitIOContext>({ client: null, treatments: null })

type ProviderProps = {
  children: React.ReactNode
}

const split: { treatments: SplitIO.Treatments | null } = { treatments: null }

const SplitIOProvider = ({ children }: ProviderProps) => {
  const [client, setClient] = useState<SplitIO.IClient | null>(null)
  const [treatments, setTreatments] = useState<SplitIO.Treatments | null>(null)
  const { currentUser } = useCurrentUser()

  const contextValues = useMemo(() => {
    split.treatments = treatments
    return { client, treatments }
  }, [client, treatments])

  useEffect(() => {
    initSplit(currentUser, (treatmentValues, splitIOClient) => {
      setClient(splitIOClient)
      setTreatments(treatmentValues)
    })
  }, [currentUser])

  return <SplitIOContext.Provider value={contextValues}>{children}</SplitIOContext.Provider>
}

// TODO: add HoC typing here and for all wrapped components
const withSplitIO = (Component: ComponentType<WithSplitIOContext>) =>
  class Consumer extends React.PureComponent {
    render() {
      return (
        <SplitIOContext.Consumer>
          {({ client, treatments }) => (
            <Component client={client} treatments={treatments} {...this.props} />
          )}
        </SplitIOContext.Consumer>
      )
    }
  }

const useSplitContext = () => {
  return useContext(SplitIOContext)
}

export { split, SplitIOContext, SplitIOProvider, withSplitIO, useSplitContext }
