import logging from 'shared/utils/logging'

import {
  getUserMaskBearerToken,
  removeUserMaskBearerToken,
  setUserMaskBearerToken,
} from './UserMaskStorage'

type StorageCache = {
  [key: string]: string | null
}

let cache: StorageCache = {}

const storage = getUserMaskBearerToken() ? sessionStorage : localStorage

function set(key: string, value: string) {
  cache[key] = value
  try {
    storage.setItem(key, value)
  } catch (e) {
    if (!(e instanceof Error)) {
      throw e
    }

    logging.logWarning('Local Storage Write Fail', e)
  }
}

function get(key: string, { skipCache }: { skipCache?: boolean } = {}) {
  if (!skipCache && cache[key]) {
    return cache[key]
  } else {
    try {
      const value = storage.getItem(key)
      cache[key] = value
      return value
    } catch (e) {
      if (!(e instanceof Error)) {
        throw e
      }

      logging.logWarning('Local Storage Get Fail', e)
      return null
    }
  }
}

function remove(key: string) {
  delete cache[key]
  try {
    storage.removeItem(key)
  } catch (e) {
    if (!(e instanceof Error)) {
      throw e
    }

    logging.logWarning('Local Storage Delete Fail', e)
  }
}

function clear() {
  cache = {}

  try {
    storage.clear()
  } catch (e) {
    if (!(e instanceof Error)) {
      throw e
    }

    logging.logWarning('Local Storage Clear Fail', e)
  }
}

export default {
  get,
  remove,
  set,

  getUserMaskBearerToken,
  removeUserMaskBearerToken,
  setUserMaskBearerToken,

  clear,
}
