type PayloadType = {
  eventName: string
  data: unknown
  callback?: {
    success?: (obj: unknown) => void
  }
}
type CallbackFnType = (obj: PayloadType) => void

// This is a custom rewrite of https://github.com/facebook/flux/blob/main/src/Dispatcher.js

const _callbacks: Record<string, CallbackFnType> = {}
let isDispatching = false
const isHandled: Record<string, boolean> = {}
const isPending: Record<string, boolean> = {}
let lastID = -1
let pendingPayload: PayloadType

const invokeCallback = (id: string) => {
  isPending[id] = true
  _callbacks[id](pendingPayload)
  isHandled[id] = true
}

const startDispatching = (payload: PayloadType) => {
  Object.keys(_callbacks).forEach((id) => {
    isPending[id] = false
    isHandled[id] = false
  })
  pendingPayload = payload
  isDispatching = true
}

const stopDispatching = () => {
  isDispatching = false
}

const Dispatcher = {
  register: (callback: CallbackFnType) => {
    lastID += 1
    const strId = `${lastID}`
    _callbacks[strId] = callback
    return strId
  },

  unregister: (id: string) => {
    if (!_callbacks[id]) {
      throw Error(`Dispatcher.unregister(...): ${id} does not map to a registered callback.`)
    }
    delete _callbacks[id]
  },

  send: (eventName: string, data?: unknown, callback?: PayloadType['callback']) => {
    return Dispatcher.dispatch({ eventName, data, callback })
  },

  dispatch: (payload: PayloadType) => {
    if (isDispatching) {
      throw Error('Can not dispatch in the middle of a dispatch')
    }

    startDispatching(payload)

    try {
      Object.keys(_callbacks).forEach((id) => {
        if (isPending[id]) {
          return
        }
        invokeCallback(id)
      })
    } finally {
      stopDispatching()
    }
  },
}

export default Dispatcher
