import { isString, last } from 'lodash'
import type { User } from 'shared/utils/logging/types/User'
import throttle from 'shared/utils/logging/throttle'

let TAP = window.ENV?.FE_ROLLBAR_TO_CONSOLE ?? false

/**
 * Identify a user in Rollbar.
 *
 * @see https://docs.rollbar.com/docs/integration-with-rollbarjs
 * @see https://docs.rollbar.com/docs/rollbarjs-configuration-reference
 * @see https://docs.rollbar.com/docs/javascript#section-quick-start-browser
 */

const identify = (user: User) => {
  if (window?.Rollbar) {
    const { id, company, full_name } = user
    window.Rollbar.configure({
      payload: {
        person: {
          companyName: company?.name,
          companyType: company?.type,
          id: id?.toString(),
          username: full_name,
        },
      },
    })
  }
}

const queue = {
  debug: {},
  info: {},
  error: {},
  warning: {},
  critical: {},
}

type Context = { args?: Rollbar.LogArgument[]; data?: Array<{ args: Rollbar.LogArgument[] }> }

const logger = {
  log(name: string, data: Context, status: Rollbar.Level) {
    const func = status ?? 'log'

    let context: Context | undefined = data
    if ('data' in context) {
      context = last(context.data)
    }
    const args = context?.args

    Rollbar[func](...(args ?? []))
  },
}

const throttled = throttle(
  queue as Parameters<typeof throttle>['0'],
  logger as Parameters<typeof throttle>['1'],
  100
)
const log = (status: Rollbar.Level, args: Array<Rollbar.LogArgument>) => {
  const [first] = args

  if (isString(first)) {
    throttled(first, { args }, status)
  } else if ('message' in first) {
    throttled(`${first.message}`, { args }, status)
  } else {
    const params = args.filter((el) => el != null)
    if (params.length === 0) {
      Rollbar.error(new Error('Empty arguments passed to rollbar'))
      return
    }
    Rollbar[status](...params)
  }
}

const tap = (on = true) => {
  TAP = on
}

const logError = (...args: Rollbar.LogArgument[]) => {
  if (window.Rollbar) {
    if (TAP) {
      console.info('%c rollbar.error', 'color:red;', ...args)
    }
    log('error', args)
  }
}

const logWarning = (...args: Rollbar.LogArgument[]) => {
  if (window.Rollbar) {
    if (TAP) {
      console.info('%c rolbar.warn', 'color:yellow;', ...args)
    }
    log('warning', args)
  }
}

export default {
  identify,
  logError,
  logWarning,
  tap,
}
