import type { ComponentProps } from 'react'
import React from 'react'
import ErrorContent from 'design-system/components/ErrorContent'
import ErrorBoundary from './index'

type Props = {
  children: React.ReactNode
} & ComponentProps<typeof ErrorContent>

const Base = ({ children, className, message }: Props) => (
  <ErrorBoundary errorRender={() => <ErrorContent className={className} message={message} />}>
    {children}
  </ErrorBoundary>
)

export default Base
