export const px = {
  sh: '48px',
  h0: '40px',
  h1: '36px',
  h2: '32px',
  h3: '28px',
  h4: '24px',
  sh1: '22px',
  sh2: '20px',
  sh3: '18px',
  bodyLarge: '16px',
  body: '14px',
  bodySmall: '12px',
  tiny: '11px',
} as const

export const rem = {
  sh: '3.43rem',
  h0: '2.857rem',
  h1: '2.57rem',
  h2: '2.286rem',
  h3: '2rem',
  h4: '1.86rem',
  sh1: '1.57rem',
  sh2: '1.43rem',
  sh3: '1.286rem',
  bodyLarge: '1.143rem',
  body: '1rem',
  bodySmall: '0.857rem',
  tiny: '0.785',
} as const

export default px
