/**
 * @generated SignedSource<<0e6e3c1e1983fe67907170e36d08b7fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useCurrentUserIsLoggedInQuery$variables = {};
export type useCurrentUserIsLoggedInQuery$data = {
  readonly __typename: string;
  readonly isLoggedIn: boolean;
};
export type useCurrentUserIsLoggedInQuery = {
  response: useCurrentUserIsLoggedInQuery$data;
  variables: useCurrentUserIsLoggedInQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isLoggedIn",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCurrentUserIsLoggedInQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useCurrentUserIsLoggedInQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0967b2b172ca19e3b7cd11d7ffe00692",
    "id": null,
    "metadata": {},
    "name": "useCurrentUserIsLoggedInQuery",
    "operationKind": "query",
    "text": "query useCurrentUserIsLoggedInQuery {\n  __typename\n}\n"
  }
};
})();

(node as any).hash = "934e04ff156a044fc395d53cfb6580a1";

export default node;
