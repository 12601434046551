import BaseConsts from 'base_consts'

const SITE_URL = window.ENV?.SITE_URL || window.location.origin
const WEBSOCKET_URL =
  window.ENV?.WEBSOCKET_URL ||
  `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}`
const EXT = '.mobile'
const DEVICE = 'web'
const { STRIPE_PUBLIC_KEY } = window.ENV || {}
const FE_NOTIFICATION_LOG_SAMPLE_RATE = window.ENV?.FE_NOTIFICATION_LOG_SAMPLE_RATE || 0.01
const FE_NOTIFICATION_THRESHOLD = parseInt(window.ENV?.FE_NOTIFICATION_THRESHOLD, 10)

const JS_ENV = window.ENV?.JS_ENV

const Consts = {
  ...BaseConsts,
  EXT,
  DEVICE,
  STRIPE_PUBLIC_KEY,
  SITE_URL,
  WEBSOCKET_URL,
  JS_ENV,
  FE_NOTIFICATION_LOG_SAMPLE_RATE,
  FE_NOTIFICATION_THRESHOLD,
}

export {
  EXT,
  DEVICE,
  STRIPE_PUBLIC_KEY,
  SITE_URL,
  WEBSOCKET_URL,
  JS_ENV,
  FE_NOTIFICATION_LOG_SAMPLE_RATE,
  FE_NOTIFICATION_THRESHOLD,
}

export default Consts
