import { RoleType } from 'graphql/enums'

import type { User } from 'web-client/hooks/useCurrentUser'

export type { User }

export const isOnlyDispatcher = (user: User) => {
  return (
    user?.permissions?.isDispatcher && !user?.permissions?.isAdmin && !user?.permissions?.isDriver
  )
}

export const isOnlyDriver = (user: User) => {
  return (
    user?.permissions?.isDriver && !user?.permissions?.isAdmin && !user?.permissions?.isDispatcher
  )
}

// Use isSuper where possible unless you're trying to exclude feature from multi-tenancy support.
export function isSwoop(user: User) {
  return user?.company?.name === 'Swoop'
}

export const isSwoopDispatcher = (user: User) => {
  return (
    isSwoop(user) && !user?.permissions?.isRoot && user.roles.includes(RoleType.swoop_dispatcher)
  )
}

export const isFleetInHouse = (user: User) => {
  return user?.permissions?.isClient && user?.company?.inHouse
}

export const isFleetManaged = (user: User) => {
  return user?.permissions?.isClient && !user?.company?.inHouse
}

export const isMotorClub = (user: User) => {
  return (
    user?.permissions?.isClient &&
    user?.company?.name &&
    [
      'Agero',
      'Allied Dispatch Solutions',
      'Allstate',
      'Geico',
      'Nation Safe Drivers',
      'Quest',
      'Road America',
      'Tesla Motors Inc',
      'USAC',
    ].includes(user?.company?.name)
  )
}
