import { useEffect, useRef, useState } from 'react'
import datadog from 'shared/utils/logging/integrations/datadog'

type Props = {
  scriptId: string
  src: string
  skip?: boolean
} & Partial<HTMLScriptElement>

const DEFAULT_MAX_RETRIES = 3
const DEFAULT_RETRY_DELAY = 1000

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const useScriptLoad = ({ scriptId, skip = false, src, ...props }: Props) => {
  const [loaded, setLoaded] = useState(false)
  const retryCount = useRef(0)

  useEffect(() => {
    let script = document.getElementById(scriptId) as HTMLScriptElement

    const loadScript = () => {
      if (!script && !skip) {
        const retry = async () => {
          retryCount.current += 1
          await sleep(DEFAULT_RETRY_DELAY)
          void loadScript()
        }

        try {
          script = document.createElement('script')
          script.id = scriptId
          Object.assign(script, props)

          script.onload = () => {
            setLoaded(true)
          }

          script.onerror = () => {
            if (retryCount.current < DEFAULT_MAX_RETRIES) {
              datadog.logError('Onerror Script load failed', {
                scriptId,
                src,
                baseURI: window.location.href,
              })
              void retry()
            }
          }

          script.src = src
          document.body.appendChild(script)
        } catch (error) {
          if (retryCount.current < DEFAULT_MAX_RETRIES) {
            datadog.logError('Catch script load failed', {
              scriptId,
              src,
              error,
            })
            void retry()
          }
        }
      }
    }

    void loadScript()
  }, [props, retryCount, scriptId, skip, src])

  return loaded
}

export default useScriptLoad
