/**
 * @generated SignedSource<<002c9ddf10eaedbbe6e9f2bfdaebd68e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type userCheckMFAStatusQuery$variables = {
  login: string;
  mfaStrategy: string;
  mfaToken?: string | null;
  password: string;
};
export type userCheckMFAStatusQuery$data = {
  readonly mfaStatus: {
    readonly code: string | null;
  } | null;
};
export type userCheckMFAStatusQuery = {
  response: userCheckMFAStatusQuery$data;
  variables: userCheckMFAStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "login"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mfaStrategy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mfaToken"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "login",
        "variableName": "login"
      },
      {
        "kind": "Variable",
        "name": "mfaStrategy",
        "variableName": "mfaStrategy"
      },
      {
        "kind": "Variable",
        "name": "mfaToken",
        "variableName": "mfaToken"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      }
    ],
    "concreteType": "MfaStatus",
    "kind": "LinkedField",
    "name": "mfaStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "userCheckMFAStatusQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "userCheckMFAStatusQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "88a3e5e2b38c74626580a938e2048961",
    "id": null,
    "metadata": {},
    "name": "userCheckMFAStatusQuery",
    "operationKind": "query",
    "text": "query userCheckMFAStatusQuery(\n  $login: String!\n  $password: String!\n  $mfaStrategy: String!\n  $mfaToken: String\n) {\n  mfaStatus(login: $login, password: $password, mfaStrategy: $mfaStrategy, mfaToken: $mfaToken) {\n    code\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a827c83721e828129aa385d20169f7e";

export default node;
