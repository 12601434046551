import React, {
  type ComponentPropsWithoutRef,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react'
import { Polygon, useGoogleMap } from '@react-google-maps/api'
import { forEach, filter } from 'lodash'

// Converts numeric degrees to radians
function toRad(value: number) {
  return (value * Math.PI) / 180
}

// Calculates the distance between two points, as the crow flies
// Looks like haversine formula https://en.wikipedia.org/wiki/Haversine_formula
export function calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371 // Earth's radius (km)
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const _lat1 = toRad(lat1)
  const _lat2 = toRad(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(_lat1) * Math.cos(_lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

type Location = google.maps.LatLngLiteral & { viewport?: google.maps.LatLngBounds }
type Locations = (Partial<Location> | null | undefined)[]
type Padding = Parameters<google.maps.Map['fitBounds']>[1]

export const fitBounds = (
  map: google.maps.Map,
  locations: Locations,
  padding: Padding = undefined,
  routes: google.maps.DirectionsRoute[] | undefined = undefined
) => {
  const zoom = 12
  const validLocations = filter(
    locations,
    (location): location is Location => !!(location?.lat && location?.lng)
  )
  if (routes) {
    routes.forEach((route) =>
      route.legs.forEach((leg) =>
        leg.steps.forEach((step) => {
          validLocations.push(step.start_location.toJSON())
          validLocations.push(step.end_location.toJSON())
        })
      )
    )
  }
  if (validLocations.length === 1) {
    const [{ lat, lng, viewport }] = validLocations
    map.setCenter(new window.google.maps.LatLng(lat, lng))
    map.setZoom(zoom)
    if (viewport) {
      map.fitBounds(viewport)
    }
  } else if (validLocations.length > 1) {
    const bounds = new window.google.maps.LatLngBounds()
    forEach(validLocations, (location) => {
      const { lat, lng } = location
      bounds.extend(new window.google.maps.LatLng(lat, lng))
    })
    map.fitBounds(bounds, padding)
    return true
  }
  return false
}

type MapControlsProps = PropsWithChildren<{
  className?: string
  position?: keyof typeof google.maps.ControlPosition
}>

export const MapControls = ({
  children,
  className = undefined,
  position = 'LEFT_CENTER',
}: MapControlsProps) => {
  const map = useGoogleMap()
  const mapControls = map?.controls[window.google.maps.ControlPosition[position]]
  const controls = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // @ts-expect-error
    mapControls?.push(controls.current)

    return () => {
      mapControls?.pop()
    }
  }, [controls, mapControls])

  return (
    <div className={className} ref={controls}>
      {children}
    </div>
  )
}

type PolygonWithListenersProps = ComponentPropsWithoutRef<typeof Polygon> & {
  onRemove?: (polygon: google.maps.Polygon | null) => void
  onSet?: (polygon: google.maps.Polygon | null) => void
  onInsert?: (polygon: google.maps.Polygon | null) => void
}

export const PolygonWithListeners = ({
  onRemove,
  onSet,
  onInsert,
  ...props
}: PolygonWithListenersProps) => {
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null)
  const path = (polygon && polygon.getPath()) || null

  useEffect(() => {
    if (path) {
      if (onInsert) {
        window.google.maps.event.addListener(path, 'insert_at', () => onInsert(polygon))
      }
      if (onRemove) {
        window.google.maps.event.addListener(path, 'remove_at', () => onRemove(polygon))
      }
      if (onSet) {
        window.google.maps.event.addListener(path, 'set_at', () => onSet(polygon))
      }
      return () => {
        window.google.maps.event.clearInstanceListeners(path)
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [path])

  return <Polygon onLoad={(thisPolygon) => setPolygon(thisPolygon)} {...props} />
}
