enum lineHeights {
  button = '38px',
  buttonCompact = '26px',
  input = '38px',
  inputCompact = '26px',
  option = '32px',
  optionCompact = '20px',
  sh2 = '26px',
  sh3 = '26px',
  bodyLarge = '22px',
  body = '20px',
  bodySmall = '18px',
  tiny = '17px',
}

export default lineHeights
