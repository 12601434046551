import styled from '@emotion/styled'
import { Link } from 'react-router5'

import colors from 'design-system/tokens/colors'

import Button from './Button'

const ButtonLink = styled(Button)`
  :hover {
    color: ${colors.white};
  }
`.withComponent(Link)

export default ButtonLink
