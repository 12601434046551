import React, { Suspense } from 'react'
import { useRoute } from 'react-router5'

import './polyfills'
import { ModalProvider } from 'design-system/components/Modals'
import TimeProvider from 'web-client/components/Time/TimeProvider'
import useCurrentUser from 'web-client/hooks/useCurrentUser'
import useFeaturesEnabled from 'web-client/hooks/useFeaturesEnabled'
import useWalkMe from 'web-client/hooks/useWalkMe'
import Layout from 'design-system/components/Layout'
import lazyRetry from 'web-client/router/lazyRetry'

import routes from './routes'

const BrownFieldApp = lazyRetry(() => import('./Router'))

function LazyLoader() {
  const router5Context = useRoute()

  // preload and avoid Relay GC
  useCurrentUser()
  useFeaturesEnabled()

  useWalkMe()

  const route = routes.find(({ name }) => name === router5Context.route.name)

  return (
    <Suspense fallback={null}>
      <TimeProvider>
        <ModalProvider>
          {route.green ? (
            <Layout
              hideFooter={route.hideFooter}
              requireAuthentication={route.requireAuthentication}
            >
              {React.createElement(route.component)}
            </Layout>
          ) : (
            <BrownFieldApp />
          )}
        </ModalProvider>
      </TimeProvider>
    </Suspense>
  )
}

export default LazyLoader
