export default {
  xxxl: '60px',
  xxl: '48px',
  xl: '40px',
  l: '36px',
  m3: '32px',
  m2: '28px',
  m1: '24px',
  s: '20px',
  xs: '16px',
  xxs: '12px',
  xxxs: '8px',
  columnGap: '16px',
  rowGap: '22px',
} as const
