import logging from 'shared/utils/logging'

function retry<T>(loadComponent: () => Promise<T>, retriesLeft = 5, interval = 1000) {
  return new Promise<T>((resolve, reject) => {
    loadComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            logging.logWarning('failed to load component')
            reject(error)
            return
          }

          logging.logInfo('retry loading component', {})
          retry(loadComponent, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export default retry
