// TODO: set up subscription for if swoop api url changes
const baseUrlWrapper = () => {
  const baseUrl = window.ENV?.SWOOP_API_URL || ''

  return {
    getBaseUrl: () => baseUrl,
  }
}

const { getBaseUrl } = baseUrlWrapper()

export { getBaseUrl }
