import type { IconSizes } from './iconSizes'

export type ButtonSizes = 'xsmall' | 'small' | 'medium' | 'large'

export const px: Record<ButtonSizes, string> = {
  xsmall: '24px',
  small: '36px',
  medium: '40px',
  large: '48px',
} as const

export const iconSizes: Record<ButtonSizes, IconSizes> = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const

export default px
