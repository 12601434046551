import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import type { Variants } from 'framer-motion'

import colors from 'design-system/tokens/colors'
import spacing from 'design-system/tokens/spacing'

type Props = {
  alignment?: 'top' | 'middle' | 'bottom' | 'center'
  children: React.ReactNode
  cardStyle?: string
  dataTestId?: string
  variants?: Variants
  className?: string
}

const StyledDiv = styled(motion.div, {
  shouldForwardProp: (propName) => propName !== 'cardStyle',
})<Props>`
  background: ${colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colors.grays.l1};
  cursor: default;
  display: inline-block;
  font-size: 1rem;
  margin: ${spacing.m1};
  position: relative;
  text-align: left;
  vertical-align: ${({ alignment }) => (alignment === 'center' ? 'middle' : alignment)};

  @media screen and (max-width: 734px) {
    margin: ${spacing.xxs};
  }

  ${({ cardStyle }) => cardStyle}

  @media (prefers-reduced-motion) {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
`

const Card = ({
  alignment,
  cardStyle,
  children,
  dataTestId,
  className,
  variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { type: 'tween', duration: 0.2, ease: 'easeInOut' },
    },
    exit: {
      opacity: 0,
      transition: { type: 'tween', duration: 0.1, ease: 'easeInOut' },
    },
  },
}: Props) => (
  <StyledDiv
    alignment={alignment}
    animate="animate"
    aria-labelledby="modal_title"
    aria-modal
    cardStyle={cardStyle}
    className={className}
    data-testid={dataTestId}
    exit="exit"
    initial="initial"
    role="dialog"
    variants={variants}
  >
    {children}
  </StyledDiv>
)

export default Card
