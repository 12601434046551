import React from 'react'
import styled from '@emotion/styled'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import colors from 'design-system/tokens/colors'
import { px as buttonPx, iconSizes } from 'design-system/tokens/buttonSizes'
import type { ButtonSizes } from 'design-system/tokens/buttonSizes'
import type { IconSizes } from 'design-system/tokens/iconSizes'
import type { SwoopColor } from 'design-system/tokens/colors'
import { Icon } from 'design-system/components/Icon'

type StyledIconButtonProps = React.HTMLProps<HTMLButtonElement> & {
  buttonSize?: ButtonSizes
  color?: SwoopColor
}

const StyledIconButton = styled.button<StyledIconButtonProps>`
  color: ${({ color }) => color || colors.grays.l2};
  &:hover {
    color: ${colors.primary};
  }
  background: none;
  border: none;
  padding: 0;
  width: ${({ buttonSize }) => (buttonSize ? buttonPx[buttonSize] : buttonPx.medium)};
  height: ${({ buttonSize }) => (buttonSize ? buttonPx[buttonSize] : buttonPx.medium)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;

    ${Icon} {
      color: ${colors.grays.l4};
    }
  }
`

type IconButtonProps = React.ComponentProps<typeof StyledIconButton> & {
  buttonSize?: ButtonSizes
  icon: IconProp
  iconSize?: IconSizes
  color?: string
  role?: string
}

const IconButton = ({
  buttonSize = 'medium',
  color,
  icon,
  iconSize,
  role = 'button',
  ...props
}: IconButtonProps) => {
  const renderIconSize: IconSizes = iconSize || iconSizes[buttonSize]
  return (
    <StyledIconButton buttonSize={buttonSize} role={role} {...props}>
      <Icon color={color} data-testid="icon-button-icon" icon={icon} iconSize={renderIconSize} />
    </StyledIconButton>
  )
}

export default IconButton
