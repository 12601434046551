import { useContext } from 'react'
import { SplitIOContext } from 'shared/components/split.io'
import type Consts from 'consts'
import useSplitAttributes from 'shared/hooks/useSplitWithTimeout/useSplitDefaultAttributes'
import rollbar from 'shared/utils/logging/integrations/rollbar'

export type AvailableSplits = (typeof Consts.SPLITS)[keyof typeof Consts.SPLITS] | 'test-split'

/**
 * @deprecated Please use `useSplitWithTimeout`
 * - loading timeout fallback is built-in.
 * - named parameters
 * - `null` return value is available as a loading state
 */
function useSplit<T = boolean>(
  splitName: AvailableSplits,
  attributes?: SplitIO.Attributes,
  defaultRet?: T,
  positiveTreatments: string[] = ['on']
) {
  const { client } = useContext(SplitIOContext)

  const defaultAttributes = useSplitAttributes()

  if (window.SPLIT_TREATMENTS) {
    if (typeof window.SPLIT_TREATMENTS[splitName] === 'boolean') {
      return window.SPLIT_TREATMENTS[splitName]
    } else {
      rollbar.logError(`Split override with no treatment not found for ${splitName}`)
    }
  }

  const treatment = client?.getTreatment(splitName, {
    ...defaultAttributes,
    ...attributes,
  })

  if (treatment) {
    if (positiveTreatments.includes(treatment)) {
      return true
    } else if (treatment === 'off') {
      return false
    }
  }
  // insert control code here
  return defaultRet || false
}

export default useSplit
