export type IconSizes = 'xsmall' | 'small' | 'medium' | 'large'

export const px: Record<IconSizes, string> = {
  xsmall: '16px',
  small: '18px',
  medium: '20px',
  large: '24px',
} as const

export default px
