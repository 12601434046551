import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { rgba } from 'polished'
import colors from 'design-system/tokens/colors'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

type InnerCircleProps = {
  /**
   * Thickness of circle and arc
   */
  thickness?: string

  /**
   * Color of main circle in `hex` format
   */
  circleColor?: string
}

const InnerCircle = styled.div<InnerCircleProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  border: ${(props) => props.thickness} solid
    ${(props) => rgba(props.circleColor ?? colors.grays.l4, 0.9)};
  box-sizing: border-box;
`

const viewerAnimation = keyframes`
  33.33% {
    transform: rotate(0deg);
  }
  66.66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
`

const Viewer = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  transform-origin: left;
  overflow: hidden;
  animation: ${viewerAnimation} 1.4s linear infinite;
`

const arcAnimation = keyframes`
  33.33% {
    transform: rotate(225deg);
  }
  66.66% {
    transform: rotate(225deg);
  }
  100% {
    transform: rotate(405deg);
  }
`

type ArcProps = {
  /**
   * Thickness of circle and arc
   */
  thickness?: string

  /**
   * Color of running arc in `hex` format
   */
  progressColor?: string
}

const Arc = styled.div<ArcProps>`
  width: 200%;
  height: 100%;
  left: -100%;
  position: absolute;
  transform-origin: center;
  border-radius: 50%;
  border-width: ${(props) => props.thickness};
  border-style: solid;
  border-color: transparent transparent
    ${(props) => `${props.progressColor} ${props.progressColor}`};
  transform: rotate(45deg);
  animation: ${arcAnimation} 1.4s linear infinite;
  box-sizing: border-box;

  @media (prefers-reduced-motion) {
    transform: none !important;
    animation: none !important;
  }
`

type LoadingSpinnerProps = InnerCircleProps & ArcProps & React.HTMLAttributes<HTMLElement>

/**
 * Loading Spinner fills whole parent with 100% width and 100% height.<br />
 * So define desired size using parent container or extend Loading Spinner to accept `size` property.<br /><br />
 * Please see stories' code for details.
 */
const LoadingSpinner = ({
  circleColor,
  thickness = '4px',
  progressColor = colors.primary,
  ...props
}: LoadingSpinnerProps) => (
  <Container {...props}>
    <InnerCircle circleColor={circleColor} thickness={thickness} />
    <Viewer>
      <Arc progressColor={progressColor} thickness={thickness} />
    </Viewer>
  </Container>
)

export const RegularLoadingSpinner = styled(LoadingSpinner)`
  width: 50px;
  height: 50px;
  margin: 0 auto;
`

export const SmallLoadingSpinner = styled(LoadingSpinner)`
  width: 24px;
  height: 24px;
  margin: 0 auto;
`
const BaseTinyLoadingSpinner = styled(LoadingSpinner)`
  width: 16px;
  height: 16px;
  margin: 0 auto;
`

export const TinyLoadingSpinner = ({ ...props }: LoadingSpinnerProps) => (
  <BaseTinyLoadingSpinner thickness="2px" {...props} />
)

export default LoadingSpinner
