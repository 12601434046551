const callWhen = (predicateFunc: () => boolean, callback: () => void, ms: number) => {
  const interval = setInterval(() => {
    if (predicateFunc()) {
      clearInterval(interval)
      callback()
    }
  }, ms)
}

export default callWhen
