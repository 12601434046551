import React, { Suspense } from 'react'

import useCurrentUser from 'web-client/hooks/useCurrentUser'
import lazyRetry from 'web-client/router/lazyRetry'
import type { HeaderProps } from './LoggedIn/Header'
import LogoOnlyHeader from './LogoOnlyHeader'

const LoggedInHeader = lazyRetry(() => import('./LoggedIn/Header'))

const Header = (props: HeaderProps) => {
  const { isLoading, isLoggedIn } = useCurrentUser()

  if (isLoading) {
    return null
  }

  const loggedOutHeader = <LogoOnlyHeader useSwoopLogo />
  if (!isLoggedIn) {
    return loggedOutHeader
  }
  return (
    <Suspense fallback={loggedOutHeader}>
      <LoggedInHeader {...props} />
    </Suspense>
  )
}

export default Header
