import type { Credentials as LegacyCredentials } from 'web-client/utils/authenticate'
import legacyAuthenticateWithOauth from 'web-client/utils/authenticate'
import logging from 'shared/utils/logging'

const oauthService = {
  async authenticate(credentials: LegacyCredentials) {
    await legacyAuthenticateWithOauth(credentials)

    logging.logInfo('Logged In with Credentials', {
      feature: 'oauth',
    })
  },
}

export default oauthService
