import type { RequestParameters } from 'relay-runtime'

import type swoopFetch from 'web-client/utils/swoopFetch'
import datadog from '../logging/integrations/datadog'

type SwoopFetch = typeof swoopFetch
type SwoopFetchResponse = Awaited<ReturnType<SwoopFetch>>

// Only allow 3 retries per loaded session
const maxRetries = 3
let retryCount = 0

const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

const retry = (fetch: typeof window.fetch | SwoopFetch, operation: RequestParameters) => {
  const delay = 3000 // 3 seconds
  const errorCodes = [502, 503, 504, 520]

  const fetchWithRetry = async (
    url: string,
    options: RequestInit
  ): Promise<Response | SwoopFetchResponse> => {
    const response = await fetch(url, options)

    if (operation.operationKind !== 'query' || !errorCodes.includes(response.status || 0)) {
      if (!response.ok) {
        datadog.logError(
          `[relayRetry] Graphql query ${operation.name} failed with status ${response.status}`,
          {
            statusCode: response.status,
          }
        )
      }
      return response
    }

    if (retryCount >= maxRetries) {
      datadog.logError(
        `[relayRetry] Graphql query ${operation.name} failed with status ${response.status}. Max retries reached`,
        {
          retryCount,
          statusCode: response.status,
        }
      )
      return response
    }

    retryCount += 1

    datadog.logError(
      `[relayRetry] Graphql query ${operation.name} failed with status ${response.status}. Retrying with delay ${delay}ms`,
      {
        retryCount,
        statusCode: response.status,
      }
    )

    await sleep(delay)

    const retryResponse = await fetch(url, options)

    if (retryResponse.ok) {
      datadog.logInfo(`[relayRetry] Graphql query ${operation.name} succeeded after retry`, {})
    } else {
      datadog.logError(
        `[relayRetry] Graphql query ${operation.name} failed after retry with status ${retryResponse.status}`,
        {
          retryCount,
          statusCode: response.status,
        }
      )
    }

    return retryResponse
  }

  return fetchWithRetry
}

export default retry
