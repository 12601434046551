import styled from '@emotion/styled'
import BaseErrorBoundary from 'shared/components/ErrorBoundary/Base'

const Global = styled(BaseErrorBoundary)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default Global
