import type { PropsWithChildren } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import Button from 'design-system/components/Button'
import fontSizes from 'design-system/tokens/fontSizes'
import fontWeights from 'design-system/tokens/fontWeights'
import fonts from 'design-system/tokens/fonts'
import spacing from 'design-system/tokens/spacing'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  padding: ${spacing.m3};
`

const Title = styled.h3`
  color: inherit;
  font-family: ${fonts.body};
  font-size: ${fontSizes.h3};
  font-weight: ${fontWeights.semibold};
  line-height: 32px;
  text-align: center;
  margin-bottom: ${spacing.xs};

  @media (max-width: 600px) {
    font-size: ${fontSizes.h4};
    margin: 0 0 ${spacing.m1};
  }
`

const Body = styled.div`
  color: inherit;
  font-family: ${fonts.body};
  font-size: ${fontSizes.bodyLarge};
  line-height: 20px;
  text-align: center;
  margin-bottom: ${spacing.m1};

  @media (max-width: 600px) {
    margin: 0 0 ${spacing.xl};

    p {
      margin: 0;
    }
  }
`

const StyledButton = styled(Button)`
  font-family: ${fonts.body};

  @media (max-width: 600px) {
    font-size: ${fontSizes.bodyLarge};
    padding: 6px;
    width: 100%;
  }
`

export const getErrorText = () => {
  // use env var to set different message for ADAC and other possible environments
  // for example we want users to contact ADAC support on ADAC env
  const text =
    window.ENV?.GLOBAL_ERROR_BOUNDARY_TEXT ||
    'Please refresh the page. If you continue to experience an issue, please contact Swoop support.'

  return text
}

const Bottom = ({ children }: { children?: React.ReactNode }) => {
  if (children) {
    return children
  }

  return (
    <StyledButton
      onClick={() => {
        window.location.reload()
      }}
    >
      Refresh
    </StyledButton>
  )
}

type ErrorContentProps = {
  className?: string
  message?: string
  title?: string
  hideBottom?: boolean
}

const ErrorContent = ({
  className,
  message,
  title,
  hideBottom,
  children,
}: PropsWithChildren<ErrorContentProps>) => (
  <Container className={className}>
    <Title>{title || `We’re sorry, a system error has occurred`}</Title>
    <Body>
      <p>{message || getErrorText()}</p>
    </Body>
    {!hideBottom && <Bottom>{children}</Bottom>}
  </Container>
)

export default ErrorContent
