import useSplit from 'shared/hooks/useSplit'
import Consts from 'base_consts'
import { useEffect, useState } from 'react'
import relayService from 'shared/utils/relay'
import { updateCableMethod } from 'graphql/createCableHandler'

const useCableSplit = () => {
  const turnOnActionCablePro: boolean | null = useSplit(
    Consts.SPLITS.ENABLE_ACTION_CABLE_PRO,
    undefined,
    null
  )

  const [waitingOnInit, setWaitingOnInit] = useState<boolean>(true)
  useEffect(() => {
    // avoids race condition where user info haven't loaded yet, and split is still determining how to handle
    if (waitingOnInit) {
      const timeout = setTimeout(() => {
        setWaitingOnInit(false)
      }, Consts.SPLIT_CABLE_HANDLER_TIMEOUT)
      return () => {
        clearTimeout(timeout)
      }
    }
  })

  useEffect(() => {
    if (!waitingOnInit) {
      if (turnOnActionCablePro !== null) {
        updateCableMethod(turnOnActionCablePro)
      } else {
        // If split is down and we are getting the default null value,
        // prevents leaving the subscription handler unset for more than 5 seconds and getting out of sync
        const timeoutId = setTimeout(() => {
          updateCableMethod(false)
        }, Consts.SPLIT_CABLE_HANDLER_TIMEOUT)
        return () => {
          clearTimeout(timeoutId)
        }
      }
    }
  }, [waitingOnInit, turnOnActionCablePro])
}

const useSplitControls = () => {
  const turnOnGraphqlJobUpdates = useSplit(Consts.SPLITS.ENABLE_GQL_JOB_UPDATES)
  // Job Store notifications and this split will be cleaned up after the job conversion are complete
  useEffect(() => {
    window.swoop?.store?.Job?.enableGraphqlJobUpdates(turnOnGraphqlJobUpdates)
  }, [turnOnGraphqlJobUpdates])

  useCableSplit()

  const turnOffBackgroundFetches = useSplit(Consts.SPLITS.DISABLE_BACKGROUND_FETCHES)
  useEffect(() => {
    relayService.setUseBackgroundFetch(!turnOffBackgroundFetches)
  }, [turnOffBackgroundFetches])

  const turnOnGraphqlRetry = useSplit(Consts.SPLITS.ENABLE_GRAPHQL_RETRY)
  useEffect(() => {
    relayService.setEnableRetry(turnOnGraphqlRetry)
  }, [turnOnGraphqlRetry])
}

export default useSplitControls
